import React from "react";
import Cards from "../../components/cards/Cards";
import "./About.scss";

class About extends React.Component {
  render() {
    return (
      <div className="about-service">
        <div className="about-container" id="about">
          <div id="about-image" className="about-us-image">
            <img src={require("../../assets/images/about-us.gif")} alt="" />
          </div>
          <div className="about-content">
            <h1>About Us</h1>
            <p>
              REO TRAVELS AND TOURS is a Nigerian travel company with a group of
              passionate individuals working together to give the best travel
              experience worldwide. Tourism or Study visa processing with the
              best advice. We are dedicated to our service delivery.
            </p>
          </div>
        </div>

        <div className="service-container" id="services">
          <h1>What we do?</h1>
          <p>Our services spans across the following</p>
          <div className="service-content">
            <Cards
              name="International Passport Application"
              image="passport-application.jpg"
              desc="One of the most important travel documents is your international passport. We assist with international passport processing, for new and renewal applications. Locations: Lagos, Abeokuta, Ibadan, Ilorin and Ekiti."
            />
            <Cards
              name="Study Visa application and Guidance"
              image="study-abroad.png"
              desc="We provided personalized guidance to suit each applicant. Study admission to schools in Australia, Canada, Ireland, Uk, USA. We also assist with visa processing and application."
            />
            <Cards
              name="Tourism Visa application and Guidance"
              image="visa-application.jpg"
              desc="From E-Visas to traditional stickers, we've got your visa needs covered for these amazing destinations: Kenya, Uganda, Dubai, South Africa, USA, UK, Canada, Egypt, Tanzania, Morocco, Malaysia, Qatar, and Schengen states! "
            />
            <Cards
              name="Conference Visa Application"
              image="conference.png"
              desc="Are you a business professional or an entrepreneur looking to advance your business knowledge, let’s help you build your travel history and also book a conference to suit your academic growth in line with your career.
              Conference visa application to Uk,USA,Canada and the Schengen states."
            />
            <Cards
              name="Canadian Permanent Residence Scheme"
              image="id-card.png"
              desc="One of the best way to relocate with one family to canada is the Canadian PR route. Let’s guide you accordingly with all the necessary information,requirements and assist to profile you for either express entry or Provisional nomination."
            />
            <Cards
              name="Brazilian Birth Tourism"
              image="birth.png"
              desc="Birth Tourism in Brazil is an opportunity for your unborn child to be a Brazilian citizen and also give the parent and the child’s siblings access to become Brazilian permanent residents. Enjoy the opportunity to travel visa free and visa on arrival to over 170 countries."
            />
            <Cards
              name="Flight Reservations"
              image="flight-booking.jpg"
              desc="Get the best flight deals for both local and international flights. Book your next flight with us today."
            />
            <Cards
              name="Hotel Bookings"
              image="hotel-booking.jpg"
              desc="Want a soft landing with readily available hotels, apartments or travel homes? We are your goto support. We provide the following; Hotel booking: Any location all over the world. Shortlet Booking: Lagos and Abuja."
            />
            <Cards
              name="Event Planning"
              image="event-planning.jpg"
              desc="Worry less about leaving a long lasting impression through memorable occasion, we got you covered! We offer the following event planning services: Company bonding, Weekend vacation, Anniversary and Birthday Get away."
            />
            <Cards
              name="Group Packages"
              image="group.jpg"
              desc="We offer local and international group packages to explore new culture, network, engage and most importantly have a good time out with family and friends."
            />
            <Cards
              name="Application for Travel Insurance"
              image="insurance.jpg"
              desc="Travel insurance is a fantastic method to safeguard all of your travel plans and receive reimbursement for any injuries or other problems that might arise. We can assist you in finding the best travel insurance."
            />
          </div>
          <div className="tour-container">
            <div id="tour-cutout" className="tour-cutout">
              Tourism
            </div>
          </div>
        </div>

        <div className="team-container">
          <div className="meet-teams">
            <img
              src={require(`../../assets/images/teams.jpeg`)}
              alt="teams"
            />

            <img
              src={require(`../../assets/images/founder.jpeg`)}
              alt="founder"
            />  
          </div>

          <div className="teams">
              <div className="other-members">
                <img
                  src={require(`../../assets/images/lead-travel-consultant.jpeg`)}
                  alt="lead travel consultant"
                />
                <img
                  src={require(`../../assets/images/travel-consultant.jpeg`)}
                  alt="travel consultant"
                />
                <img
                  src={require(`../../assets/images/it-marketing.jpeg`)}
                  alt="it marketing"
                />
                <img
                  src={require(`../../assets/images/customer-relations.jpeg`)}
                  alt="customer relation"
                />
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default About;
